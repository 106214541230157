import { render, staticRenderFns } from "./ImpactSources.vue?vue&type=template&id=120e6691"
import script from "./ImpactSources.vue?vue&type=script&lang=ts"
export * from "./ImpactSources.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImpactGoalEvidenceCardListItem: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/impact-goal/EvidenceCardListItem.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VSlideGroup,VSlideItem})
