export default defineComponent({
  props: {
    impact: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {},
  data: function data() {
    return {
      evidenceInView: null
    };
  }
});